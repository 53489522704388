<template>
  <div>
    <v-container>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 pb-2" divider=">"></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>Temporary Subscription Statistics</h1>
        </v-col>
        <v-col cols="4">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed :to="{ name: 'module-paw-subscriptions' }">
              <v-icon left>mdi-arrow-left</v-icon>Back To Subscriptions
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Paw Dog Food",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } }
        },
        {
          text: "Subscriptions",
          disabled: false,
          to: {
            name: "module-paw-subscriptions",
            params: { id: this.$route.params.id }
          }
        },
        {
          text: "Temp Subscription Stats",
          disabled: true
        }
      ]
    };
  }
};
</script>
